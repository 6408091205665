var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "customer-form",
            submitAction: _vm.create,
            submitSuccessAction: _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { width: "0", height: "0", overflow: "hidden" },
              attrs: { id: "card-reader-input-wrapper" }
            },
            [_c("input", { attrs: { id: "card-reader", type: "text" } })]
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.customer.name,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "name", $$v)
                  },
                  expression: "customer.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "surname", placeholder: _vm.$t("shared.surname") },
                model: {
                  value: _vm.customer.surname,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "surname", $$v)
                  },
                  expression: "customer.surname"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "fiscalCode",
                  placeholder: _vm.$t("shared.fiscalCode")
                },
                model: {
                  value: _vm.customer.fiscalCode,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "fiscalCode", $$v)
                  },
                  expression: "customer.fiscalCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "phoneNumber",
                  placeholder: _vm.$t("shared.phoneNumber")
                },
                model: {
                  value: _vm.customer.phoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "phoneNumber", $$v)
                  },
                  expression: "customer.phoneNumber"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "mobileNumber",
                  placeholder: _vm.$t("shared.mobileNumber")
                },
                model: {
                  value: _vm.customer.mobileNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "mobileNumber", $$v)
                  },
                  expression: "customer.mobileNumber"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "email", placeholder: _vm.$t("shared.email") },
                model: {
                  value: _vm.customer.email,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "email", $$v)
                  },
                  expression: "customer.email"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "timeSlotId",
                  placeholder: _vm.$t("customersTimeSlots.timeSlot"),
                  dataSource: _vm.timeSlots,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.customer.timeSlotId,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "timeSlotId", $$v)
                  },
                  expression: "customer.timeSlotId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "acquisitionChannelId",
                  placeholder: _vm.$t("acquisitionChannels.acquisitionChannel"),
                  dataSource: _vm.acquisitionChannels,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.customer.acquisitionChannelId,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "acquisitionChannelId", $$v)
                  },
                  expression: "customer.acquisitionChannelId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "notes", placeholder: _vm.$t("shared.notes") },
                model: {
                  value: _vm.customer.notes,
                  callback: function($$v) {
                    _vm.$set(_vm.customer, "notes", $$v)
                  },
                  expression: "customer.notes"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("h4", { staticStyle: { margin: "12px 0 6px" } }, [
                _vm._v(_vm._s(_vm.$t("services.services")))
              ]),
              _c(
                "ejs-grid",
                {
                  attrs: {
                    allowSorting: true,
                    sortSettings: _vm.sortOptions,
                    dataSource: _vm.customer.services,
                    editSettings: _vm.editSettings,
                    toolbar: _vm.toolbar,
                    actionBegin: _vm.actionBegin,
                    actionComplete: _vm.actionComplete
                  }
                },
                [
                  _c(
                    "e-columns",
                    [
                      _c("e-column", {
                        attrs: {
                          field: "officeId",
                          width: "0",
                          validationRules:
                            _vm.serviceValidationOptions.rules.officeId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "officeName",
                          headerText: _vm.$t("offices.office"),
                          width: "150",
                          visible: _vm.hasMultipleOffices
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "serviceId",
                          headerText: _vm.$t("shared.id"),
                          isPrimaryKey: true,
                          width: "0",
                          validationRules:
                            _vm.serviceValidationOptions.rules.serviceId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "serviceName",
                          headerText: _vm.$t("services.service"),
                          width: "200"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "price",
                          headerText: _vm.$t("shared.price"),
                          width: "200",
                          validationRules:
                            _vm.serviceValidationOptions.rules.price,
                          type: "number",
                          format: "C2"
                        }
                      }),
                      _c("e-column", {
                        attrs: { width: "90", commands: _vm.commands }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.create",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }